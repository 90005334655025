import React, { Fragment, useEffect, useState } from 'react';
import invoke from '../rpc';
import classname from 'classnames';
import Log from './log';
import './gameRound.css';

const invokeGameRoundHistoryService = invoke('GameRoundHistoryService');
const invokeLogService = invoke('LogService');

const GameRound = ({ match }) => {
  const gameRoundId = parseInt(match.params.gameRoundId, 10);
  const [gameRound, setGameRound] = useState();
  const [logItems, setLogItems] = useState([]);
  const [shouldShowLog, setShoudShowLog] = useState(false);
  useEffect(() => {
    invokeGameRoundHistoryService('GetGameRoundHistory', gameRoundId).then(setGameRound);
    invokeLogService('Search', gameRoundId)
      .then((results) => results.hits.hits.map(({ _source }) => ({ message: _source.message, ..._source.json })))
      .then(setLogItems, console.error);
  }, [gameRoundId]);
  const onSubmitClicked = () => {
    let topLevelDomain=window.location.origin.split(".")
    let length=topLevelDomain.length;
    let lastDomain=`.${topLevelDomain[length-2]}.${topLevelDomain[length-1]}`
    let substitute = window.location.origin.split("assets.admin-")[1].split(lastDomain)[0];

    window.open(`https://assets.${substitute}${lastDomain}/api/demo/launchReplay?game_round_id=${gameRoundId}`, "_blank");
  }
  return (
    (gameRound && (
      <div className="card mb-3">
        <h5 className="card-header text-white mb-3 bg-success">
          {gameRound.gameId}
          GameRound {gameRound.gameRoundId} created on {new Date(gameRound.tsCreated).toLocaleString()} by player{' '}
          {gameRound.playerId}
        </h5>
        <table className="table table-sm table-bordered table-hover table-striped">
          <caption>
            Commands{' '}
            <button type="button" className="btn btn-primary" onClick={() => setShoudShowLog(!shouldShowLog)}>
              {shouldShowLog ? 'Hide ' : 'Show '} Log
            </button>
          </caption>
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">created</th>
              <th scope="col">action</th>
              <th scope="col">amount</th>
            </tr>
          </thead>
          <tbody>
            {gameRound.commands.map((command) => (
              <Fragment key={command.commandId}>
                <tr>
                  <td>{command.commandId}</td>
                  <td>{new Date(command.tsCreated).toLocaleString()}</td>
                  <td>
                    {command.action.type} {command.action.arguments && command.action.arguments.join(' ')}
                  </td>
                  <td>{command.transactionAmount}</td>
                </tr>
                <tr className={classname({ inactive: !shouldShowLog })}>
                  <td colSpan="4">
                    <Log
                      items={logItems.filter((l) => l['alesund.commandId'] === command.commandId)}
                      format={({ time, caller, message }) => `${new Date(time).toLocaleString()} ${caller} ${message}`}
                    />
                  </td>
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
        <button onClick={onSubmitClicked}>
          Game History
        </button>
      </div>
    )) ||
    '...'
  );
};

export default GameRound;
