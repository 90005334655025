import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import invoke from '../rpc';
import Dropdown from 'react-dropdown';
const invokeGameRoundService = invoke('GameRoundService');
const invokeGameplayService = invoke('GameplayService');
const invokeGameService = invoke('GameService');

let gameIdArr = ["All Games"]
let currencies = [
  "All Currencies",
  "USD",
  "INR",
  "EUR",
  "GBP",
  "IDR",
  "YEN"
]

const GameRounds = () => {
  const [gameRounds, setGameRounds] = useState(JSON.parse(window.localStorage.getItem('GameRounds')) || []);
  const gameRoundServiceParams = JSON.parse(window.localStorage.getItem('GameRoundServiceParams'));
  let [tsCreatedFrom, tsCreatedTo, playerId, gameRoundId] = Array.from({ length: 4 }, useRef);
  (gameRoundServiceParams != null) && ([tsCreatedFrom, tsCreatedTo, playerId, gameRoundId] = gameRoundServiceParams);
  const [games, setGames] = useState(JSON.parse(window.localStorage.getItem('GameSearchDetails')) || []);
  const [gameID, setGameID] = useState(JSON.parse(window.localStorage.getItem('GameRoundGameId')) || null);
  const [currency, setCurrency] = useState(JSON.parse(window.localStorage.getItem('GameRoundCurrency')) || null);
  gameIdArr = ["All Games"];
  games.map(({ gameId }, index) => (gameIdArr.push(gameId)));
  const getGMTDateTimeString = (localDateTimeString, currentTime) => {
    let localDateObject = null;
    if (currentTime) {
      localDateObject = new Date(localDateTimeString);
      let date = new Date();
      localDateObject.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    } else {
      localDateObject = new Date(localDateTimeString);
      localDateObject.setHours(0, 0, 0);
    }

    let day = localDateObject.getUTCDate();
    day = day < 10 ? `0${day}` : day;
    let month = (localDateObject.getUTCMonth() + 1);
    month = month < 10 ? `0${month}` : month;
    const year = localDateObject.getUTCFullYear();
    let hours = localDateObject.getUTCHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = localDateObject.getUTCMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = localDateObject.getUTCSeconds();
    seconds = seconds < 10 ? `0${seconds}` : seconds;
    let milliseconds = localDateObject.getUTCMilliseconds();
    milliseconds = milliseconds < 10 ? `00${milliseconds}` : milliseconds < 100 ? `0${milliseconds}` : milliseconds;
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }
  const getDate = (k, s) => {
    if (s.length > 0) {
      if (k === "tsCreatedFrom") {
        return getGMTDateTimeString(s)
      }
      if (k === "tsCreatedTo") {
        return getGMTDateTimeString(s, true);
      }
    } else {
      if (k === "tsCreatedFrom") {
        return getGMTDateTimeString(new Date().setHours(0, 0, 0))
      }
      if (k === "tsCreatedTo") {
        return getGMTDateTimeString(new Date().toString(), true)
      }
    }
  }
  function onchange(option) {
    setGameID(option.value === "All Games" ? null : option.value);
  }
  const onCurrencyChange = option => {
    setCurrency(option.value === "All Currencies" ? null : option.value);
  }
  useEffect(() => {
    invokeGameService('GetGames').then((result) => {
      setGames(result);
      gameIdArr = ["All Games"]
      result.map(({ gameId }, index) => (gameIdArr.push(gameId)));
      window.localStorage.setItem('GameSearchDetails', JSON.stringify(result));
    })
  }, []);
  const search = () => {
    window.localStorage.setItem('GameRoundGameId', JSON.stringify(gameID));
    window.localStorage.setItem('GameRoundCurrency', JSON.stringify(currency));
    window.localStorage.setItem('GameRoundServiceParams', JSON.stringify([
      {
        current: {
          value: tsCreatedFrom.current.value
        }
      },
      {
        current: {
          value: tsCreatedTo.current.value
        }
      },
      {
        current: {
          value: playerId.current.value
        }
      },
      {
        current: {
          value: gameRoundId.current.value
        }
      }
    ]));
    const payload = Object.entries({ tsCreatedFrom, tsCreatedTo, playerId, gameRoundId }).map(([k, v]) => [
      k,
      { number: (k, s) => parseInt(s, 10), text: (k, s) => s, date: (k, s) => getDate(k, s) }[v.current.type](
        k, v.current.value
      ),
    ]).filter(([, v]) => v).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

    if (gameID != null && gameID != "") {
      payload.gameId = gameID
    }
    if (currency != null && currency != "") {
      payload.currency = currency;
    }
    invokeGameRoundService(
      'SearchGameRounds',
      payload
    ).then(data => {
      setGameRounds(data);
      window.localStorage.setItem('GameRounds', JSON.stringify(data));
    });
  }
  const complete = (gameRoundId, playerId, gameId, isPlayForFun, currency) =>
    invokeGameplayService('CompleteGameRound', gameRoundId, playerId, gameId, isPlayForFun, currency);
  return (
    <>
      <form>
        <div className="form-group row mt-3">
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedFrom.current && tsCreatedFrom.current.value} type="date" ref={tsCreatedFrom} />
          </div>
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedTo.current && tsCreatedTo.current.value} type="date" ref={tsCreatedTo} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <input className="form-control" defaultValue={gameRoundId.current && gameRoundId.current.value} placeholder="gameRoundId" type="number" ref={gameRoundId} />
          </div>
          <div className="col-sm-2">
            <input className="form-control" defaultValue={playerId.current && playerId.current.value} placeholder="playerId" type="text" ref={playerId} />
          </div>
          <div className="col-sm-3">
            <Dropdown value={gameID == null ? "All Games" : gameID} className="form-control" onChange={onchange} options={gameIdArr} placeholder="Select GameID" type="text" />
          </div>
          <div className="col-sm-3">
            <Dropdown value={currency == null ? "All Currencies" : currency} className="form-control" onChange={onCurrencyChange} options={currencies} placeholder="Select Currency" type="text" />
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-block" type="button" onClick={search}>
              Search
            </button>
          </div>
        </div>
      </form>
      {gameRounds && (
        <InfiniteScroll
          dataLength={gameRounds.length}
          next={() => search(false)}
          hasMore={false}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>The End</b>
            </p>
          }
        >
          <table className="table table-bordered table-hover table-striped">
            <tbody>
              {gameRounds.map(({ gameRoundId, playerId, gameId, isPlayForFun, currency, tsCreated }) => (
                <tr key={gameRoundId}>
                  <td>{gameRoundId}</td>
                  <td>{playerId}</td>
                  <td>{gameId}</td>
                  <td>{isPlayForFun ? 'fun' : 'real'}</td>
                  <td>{currency}</td>
                  <td>{new Date(tsCreated).toLocaleString()}</td>
                  <td>
                    <Link to={`/log/${gameRoundId}`}>log</Link>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary"
                      type="button"
                      onClick={() => complete(gameRoundId, playerId, gameId, isPlayForFun, currency)}
                    >
                      Complete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
    </>
  );
};

export default GameRounds;
