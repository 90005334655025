import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import invoke from '../rpc';
import Dropdown from 'react-dropdown';
import { CSVLink, CSVDownload } from "react-csv";



const invokeGameRoundHistoryService = invoke('GameRoundHistoryService');
const size = 300;

Array.prototype.sum = function (prop) {
  var total = 0
  for (var i = 0, _len = this.length; i < _len; i++) {
    total += this[i][prop]
  }
  return total
}
let gameIdArr = ["All Games"]
let currencies = [
  "All Currencies",
  "USD",
  "INR",
  "EUR",
  "GBP",
  "IDR",
  "YEN"
]

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}


const invokeGameService = invoke('GameService');

const GameRounds = () => {
  console.log('Version 2.0.0');
  const [gameRounds, setGameRounds] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistory')) || []);
  const [hasMore, setHasMore] = useState(true);
  const [gameID, setGameId] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryGameId')) || null);
  const [games, setGames] = useState(JSON.parse(window.localStorage.getItem('GameSearchDetails')) || []);
  const [currency, setCurrency] = useState(JSON.parse(window.localStorage.getItem('GameRoundHistoryCurrency')) || null);

  gameIdArr = ["All Games"];
  games.map(({ gameId }, index) => (gameIdArr.push(gameId)));
  const gameRoundHistoryServiceParams = JSON.parse(window.localStorage.getItem('GameRoundHistoryServiceParams'));
  let [tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId] = Array.from({ length: 5 }, useRef);
  (gameRoundHistoryServiceParams != null) && ([tsCreatedFrom, tsCreatedTo, playerId, gameRoundId, gameId] = gameRoundHistoryServiceParams);
  const onCurrencyChange = option => {
    setCurrency(option.value === "All Currencies" ? null : option.value);
  }
  const getGMTDateTimeString = (localDateTimeString, currentTime = false) => {
    let localDateObject = null;
    if (currentTime) {
      localDateObject = new Date(localDateTimeString);
      let date = new Date();
      localDateObject.setHours(date.getHours(), date.getMinutes(), date.getSeconds());
    } else {
      localDateObject = new Date(localDateTimeString);
      localDateObject.setHours(0, 0, 0);
    }

    let day = localDateObject.getUTCDate();
    day = day < 10 ? `0${day}` : day;
    let month = (localDateObject.getUTCMonth() + 1);
    month = month < 10 ? `0${month}` : month;
    const year = localDateObject.getUTCFullYear();
    let hours = localDateObject.getUTCHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = localDateObject.getUTCMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function onchange(option) {

    setGameId(option.value === "All Games" ? null : option.value);

  }
  useEffect(() => {
    invokeGameService('GetGames').then((result) => {
      setGames(result);
      gameIdArr = ["All Games"]
      result.map(({ gameId }, index) => (gameIdArr.push(gameId)));
      window.localStorage.setItem('GameSearchDetails', JSON.stringify(result));
    })

  }, []);





  const search = (isNew) => {
    console.log("Search Started");
    window.localStorage.setItem('GameRoundHistoryGameId', JSON.stringify(gameID));
    window.localStorage.setItem('GameRoundHistoryCurrency', JSON.stringify(currency));
    window.localStorage.setItem('GameRoundHistoryServiceParams', JSON.stringify([
      {
        current: {
          value: tsCreatedFrom.current.value
        }
      },
      {
        current: {
          value: tsCreatedTo.current.value
        }
      },
      {
        current: {
          value: playerId.current.value
        }
      },
      {
        current: {
          value: gameRoundId.current.value
        }
      },
      {
        current: {
          value: gameId.current.value
        }
      }
    ]))
    let filter = {};
    if (currency && currency != 'Blank') {
      filter = {
        filter: {
          terms: {
            currency: [currency.toUpperCase(), currency.toLowerCase()]
          }
        }
      }
    }
    invokeGameRoundHistoryService('SearchGameRoundHistory', {
      size,
      from: isNew ? 0 : gameRounds.length,
      _source: {
        excludes: ['commands'],
      },
      query: {
        bool: {
          must: [
            ...(tsCreatedFrom.current.value || tsCreatedTo.current.value
              ? [
                {
                  range: {
                    tsCreated: {
                      ...(tsCreatedFrom.current.value ? { gte: getGMTDateTimeString(tsCreatedFrom.current.value) } : { gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)) }),
                      ...(tsCreatedTo.current.value ? { lte: getGMTDateTimeString(tsCreatedTo.current.value, true) } : { lte: getGMTDateTimeString(new Date().toString(), true) }),
                    },
                  },
                },
              ]
              : [
                {
                  range: {
                    tsCreated: {
                      gte: getGMTDateTimeString(new Date().setHours(0, 0, 0)),
                      lte: getGMTDateTimeString(new Date().toString(), true)
                    }
                  }
                }
              ]),
            ...(gameRoundId.current.value ? [{ term: { _id: +gameRoundId.current.value } }] : []),
            ...(playerId.current.value ? [{ term: { playerId: playerId.current.value } }] : []),
            ...((gameID == "Blank" ? "" : gameID) ? [{
              wildcard: {
                gameId: `*${gameID.split("-")[gameID.split("-").length - 1].toLowerCase()}*`
              }
            }] : []),
          ],
          ...filter

        },
      },
    })
      .then(({ hits }) => hits.hits.map(({ _source }) => _source))
      .then((moreGameRounds) => {
        const unSortedGameRounds = isNew ? [...moreGameRounds] : [...gameRounds, ...moreGameRounds];
        const sortedGameRounds = unSortedGameRounds.sort(function (a, b) { return a.gameRoundId - b.gameRoundId });
        setGameRounds(sortedGameRounds);
        window.localStorage.setItem('GameRoundHistory', JSON.stringify([...(isNew ? [] : gameRounds), ...moreGameRounds]))
        setHasMore(moreGameRounds.length > 0);
      });

  };
  const formatDataForCSV = gameRounds => {
    const formattedGameRounds = [];
    gameRounds.forEach(gameRound => {
      const formattedGameRound = {};
      const localDate = new Date(gameRound.tsCreated);
      let day = localDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let month = (localDate.getMonth() + 1);
      month = month < 10 ? `0${month}` : month;
      const year = localDate.getFullYear();
      formattedGameRound['Date'] = `${day}-${month}-${year}`;
      formattedGameRound['Game Round Id'] = gameRound.gameRoundId;
      formattedGameRound['Player Id'] = gameRound.playerId;
      formattedGameRound['Game Id'] = gameRound.gameId;
      formattedGameRound['Mode'] = gameRound.isPlayForFun ? 'fun' : 'real';
      formattedGameRound['Currency'] = gameRound.currency;
      formattedGameRound['Start Date'] = new Date(gameRound.tsCreated).toLocaleString();
      formattedGameRound['End Date'] = new Date(gameRound.tsCompleted).toLocaleString();
      formattedGameRound['STAKE'] = gameRound.totalStake;
      formattedGameRound['WIN'] = gameRound.totalReturn;
      formattedGameRound['GGR'] = gameRound.totalStake - gameRound.totalReturn;
      formattedGameRounds.push(formattedGameRound);
    });
    return formattedGameRounds;
  }
  return (
    <>
      <form className="formLabel">
        <div className="form-group row mt-3">
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedFrom.current && tsCreatedFrom.current.value} type="date" ref={tsCreatedFrom} />
          </div>
          <div className="col-sm-6">
            <input className="form-control" defaultValue={tsCreatedTo.current && tsCreatedTo.current.value} type="date" ref={tsCreatedTo} />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2">
            <input className="form-control" defaultValue={gameRoundId.current && gameRoundId.current.value} placeholder="gameRoundId" type="number" ref={gameRoundId} />
          </div>
          <div className="col-sm-2">
            <input className="form-control" defaultValue={playerId.current && playerId.current.value} placeholder="playerId" type="text" ref={playerId} />
          </div>
          <div className="col-sm-3">
            <Dropdown value={gameID == null ? "All Games" : gameID} className="form-control" onChange={onchange} options={gameIdArr} placeholder="Select GameID" type="text" ref={gameId} />
          </div>
          <div className="col-sm-3">
            <Dropdown value={currency == null ? "All Currencies" : currency} className="form-control" onChange={onCurrencyChange} options={currencies} placeholder="Select Currency" type="text" />
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-block" type="button" onClick={() => search(true)}>
              Search
            </button>
          </div>
        </div>
      </form>


      <div>
        <h1 className='dashHeader'>Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(gameRounds)}>Download Report</CSVLink></h1>


      </div>



      <table className="table table-bordered table-hover table-striped">




        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>


        <tbody>
          <tr>

            <td>{gameRounds.sum("totalStake")}</td>
            <td>{gameRounds.sum("totalReturn")}</td>
            <td>{gameRounds.sum("totalStake") - gameRounds.sum("totalReturn")}</td>
            <td>{gameRounds.length}</td>

          </tr>
        </tbody>

      </table>
      <p>* Please scroll down to the end of the page to get the final consolidated numbers for the defined date range. </p>
      {gameRounds && (


        <InfiniteScroll
          dataLength={gameRounds.length}
          next={() => search(false)}
          hasMore={hasMore}
        >
          <table className="table table-bordered table-hover table-striped">

            <thead>
              <tr>
                <td>gameRoundID</td>
                <td>playerId</td>
                <td>gameId</td>
                <td>mode</td>
                <td>currency</td>
                <td>startDate</td>
                <td>endDate</td>
                <td>Stake</td>
                <td>Win</td>
                <td>GGR</td>
              </tr>
            </thead>
            <tbody>
              {gameRounds.map(
                ({
                  gameRoundId,
                  playerId,
                  gameId,
                  isPlayForFun,
                  currency,
                  tsCreated,
                  tsCompleted,
                  totalStake,
                  totalReturn,
                }) => (
                  <tr key={gameRoundId}>
                    <td>
                      <Link to={`/gameRoundHistory/${gameRoundId}`}>{gameRoundId}</Link> (
                      <Link to={`/log/${gameRoundId}`}>log</Link>)
                    </td>
                    <td>{playerId}</td>
                    <td>{gameId}</td>
                    <td>{isPlayForFun ? 'fun' : 'real'}</td>
                    <td>{currency}</td>
                    <td>{new Date(tsCreated).toLocaleString()}</td>
                    <td>{tsCompleted && new Date(tsCompleted).toLocaleString()}</td>
                    <td>{totalStake}</td>
                    <td>{totalReturn}</td>
                    <td>{totalStake - totalReturn}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
      <div>
        <h1 className='dashHeader'>Mini DashBoard <CSVLink filename={`Game Round History Report ${new Date().toLocaleString()}`} data={formatDataForCSV(gameRounds)}>Download Report</CSVLink></h1>
      </div>
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <td>totalbet</td>
            <td>totalwin</td>
            <td>totalGGR</td>
            <td>totalGames</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{gameRounds.sum("totalStake")}</td>
            <td>{gameRounds.sum("totalReturn")}</td>
            <td>{gameRounds.sum("totalStake") - gameRounds.sum("totalReturn")}</td>
            <td>{gameRounds.length}</td>
          </tr>
        </tbody>
      </table>
      <p style={{ textAlign: 'center' }}>
        <b>The End</b>
      </p>
    </>
  );
};

export default GameRounds;
